import React from 'react';
import axios from 'axios';
import {
  useParams,
} from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Stack from 'react-bootstrap/Stack';


import cfg from './appConfig';
import { timeStamp } from 'console';

type JudgeExists = {
  [id: string]: boolean;
};

class Judgement extends React.Component<{ eventKey: number, name: string, decision?: string, opinion?: string, required?: boolean, biased?: boolean, timestamp: string }, {}> {
  constructor(props: any) {
    super(props)
  }
  render() {
    const isRequired = !!this.props.required;
    const decision = (this.props.decision && this.props.decision.length > 0) ? this.props.decision : 'Undecided';
    let symbol = '';

    const revealDay = new Date();
    revealDay.setDate(revealDay.getDate() - 2);

    let tradeTimeSet = false;
    let tradeTime = 0;
    try {
      tradeTime = new Date(this.props.timestamp).getTime();
      tradeTimeSet = true;
    } catch(e) {
      console.log("error creating date");
      console.log(e);
    }
    const revealDecision = (!tradeTimeSet) || (tradeTime >= revealDay.getTime())

    switch(decision) {
    case 'approve':
      symbol = (revealDecision) ? '✅' : '⬜';
      break;
    case 'veto':
      symbol = (revealDecision) ? '❌' : '⬜';
      break;
    default:
      symbol='❔';
    }

    const opinion = (this.props.opinion && this.props.opinion !== 'todo') ? this.props.opinion : '';
    let structuredOpinion = (<p>{opinion}</p>);

    const headline = symbol;
    let name = (<span>{this.props.name}</span>);
    if (this.props.biased) {
      name = (<i>{name}{"*"}</i>);

      structuredOpinion = (
        <span>
          <p><i>({this.props.name} is participating in this trade)</i></p>
          <p>{opinion}</p>
        </span>
      );
    }

    let suffix = '';
    if (isRequired) {
      suffix = '(committee)'
    }

    return (
      <Accordion.Item eventKey={`${this.props.eventKey}`}>
        <Accordion.Header>{headline} {name} {suffix}</Accordion.Header>
        <Accordion.Body>
          {structuredOpinion}
        </Accordion.Body>
      </Accordion.Item>  
    );
  }
}


class Transaction extends React.Component<{ tradeID: string|undefined }, { ready: boolean, trade: any }> {

  constructor(props: any) {
    super(props);

    this.state = {
      ready: false,
      trade: {}
    };
  }

  async componentDidMount() {
    const tradeRequest = await axios(`${cfg.backend}/api/trades/${cfg.leagueID}.pt.${this.props.tradeID}`);
    
    let tradeData = {};
    try {
      tradeData = await tradeRequest.data;
    } catch(e) {
      console.error(`unable to fetch from backend: ${e}`);
    }
    
    this.setState({ ready: true, trade: tradeData });
  }

  render() {
    const trade = this.state.trade;

    if (!this.state.ready) {
      return (<div></div>);
    }
  
    const [left, right, ..._] = trade['traders'];

    let status = 'Pending';
    switch(trade['status']) {
    case 5:
        status = 'Approved';
        break;
    case 6:
        status = 'Vetoed';
        break;
    }

    const timestamp = (trade.hasOwnProperty('timestamp')) ? trade.timestamp : '';

    const biasedJudges: JudgeExists = {};
    biasedJudges[left['team_key'] || 'left'] = true;
    biasedJudges[right['team_key'] || 'right'] = true;

    const judgements = trade['committee'].map((judge: any, index: number) => {
      return(
        <Judgement
          key={index}
          eventKey={index}
          name={judge['name']}
          decision={judge['decision']}
          opinion={judge['opinion']}
          required={judge['required']}
          biased={!!biasedJudges[judge['team_key']]}
          timestamp={timestamp}
        />
      );
    });

    let leftPicks = null;
    if (Object.hasOwn(left, 'picks')) {
      leftPicks = left['picks'].map((pick: string, index: number) => {
        return(
          <ListGroup.Item key={index}>Round {pick} ➡️</ListGroup.Item>
        );
      });
    }
    let leftPlayers = null;
    if (Object.hasOwn(left, 'players')) {
      leftPlayers = left['players'].map((player: string, index: number) => {
        return(
          <ListGroup.Item key={index}>{player} ➡️</ListGroup.Item>
        );
      });
    }
    let rightPicks = null;
    if (Object.hasOwn(right, 'picks')) {
      rightPicks = right['picks'].map((pick: string, index: number) => {
        return(
          <ListGroup.Item key={index}>⬅️ Round {pick}</ListGroup.Item>
        );
      });
    }
    let rightPlayers = null;
    if (Object.hasOwn(right, 'players')) {
      rightPlayers = right['players'].map((player: string, index: number) => {
        return(
          <ListGroup.Item key={index}>⬅️ {player}</ListGroup.Item>
        );
      });
    }
    return (
      <Stack gap={3}>
        <Container>
          <Row className="text-center">
            <Col>
              <h3>Status: {status}</h3>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="text-center">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Header>{left['name']}</Card.Header>
                  <ListGroup variant="flush">
                    {leftPicks}
                    {leftPlayers}
                  </ListGroup>              
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Header>{right['name']}</Card.Header>
                  <ListGroup variant="flush">
                    {rightPicks}
                    {rightPlayers}
                  </ListGroup>              
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Accordion>
                {judgements}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Stack>
    );
  }
}

function TransactionWrapper() {
  const { id } = useParams();

  return (
    <Transaction tradeID={id} />
  );
};

export default TransactionWrapper;