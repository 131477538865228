import React from 'react';
import logo from './logo.svg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { BrowserRouter as Router, Link, NavLink, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Transaction from './components/Transaction';
import cfg from './components/appConfig';

class App extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <Router basename={cfg.basename}>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand href="/">
              Panda Express League: Trades
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className="link-dark" href="/">Home</Nav.Link>
                <Nav.Link className="link-dark" href="/constitution">Constitution</Nav.Link>
                <Nav.Link className="link-dark" href="/newsletters">Newsletter</Nav.Link>
                <Nav.Link className="link-dark" href="/amendments">Amendments</Nav.Link>
                <Nav.Link className="link-dark" href="/documents">Documents</Nav.Link>
                <Navbar.Text>
                  <Link className="link-dark link-underline link-underline-opacity-0" to="/">Trades</Link>
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trade/:id" Component={Transaction} />
        </Routes>
      </Router>
    );
  }
}

export default App;
