import React from 'react';
import axios from 'axios'

import {Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';


class Trade extends React.Component<{ transactionKey: string, traders: any, status: number, judges: any }, {}> {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {}

  render() {
    const [left, right, ..._] = this.props['traders'];

    let committeeTally = 0;
    let submittedVote = 0;
    const judges = this.props['judges'] || [];
    judges.map((j: any) => {
      if (j['required']) {
        committeeTally += 1;

        if (j['decision'] && j['decision'] !== '') {
          submittedVote += 1;
        }
      }
    });
    const displayTally =  (committeeTally <= 3) ? submittedVote : '0';

    let status = `Pending (${displayTally}/3)`;
    switch(this.props['status']) {
    case 4:
      status = `Votes Submitted`;
      break;
    case 5:
      status = `Approved`;
      break;
    case 6:
      status = `Vetoed`;
      break;
    }

    const transactionParts = this.props['transactionKey'].split('.');
    const tradeID = transactionParts[transactionParts.length - 1];

    let leftPicks = null;
    if (Object.hasOwn(left, 'picks')) {
      leftPicks = left['picks'].map((pick: string, index: number) => {
        return(
          <ListGroup.Item key={index}>Round {pick} ➡️</ListGroup.Item>
        );
      });
    }
    let leftPlayers = null;
    if (Object.hasOwn(left, 'players')) {
      leftPlayers = left['players'].map((player: string, index: number) => {
        return(
          <ListGroup.Item key={index}>{player} ➡️</ListGroup.Item>
        );
      });
    }
    let rightPicks = null;
    if (Object.hasOwn(right, 'picks')) {
      rightPicks = right['picks'].map((pick: string, index: number) => {
        return(
          <ListGroup.Item key={index}>⬅️ Round {pick}</ListGroup.Item>
        );
      });
    }
    let rightPlayers = null;
    if (Object.hasOwn(right, 'players')) {
      rightPlayers = right['players'].map((player: string, index: number) => {
        return(
          <ListGroup.Item key={index}>⬅️ {player}</ListGroup.Item>
        );
      });
    }
    return (
      <Container>
        <Row className="text-center">
          <Col>
            <Card>
              <Card.Body>
                <Card.Header>{left['name']}</Card.Header>
                <ListGroup variant="flush">
                  {leftPicks}
                  {leftPlayers}
                </ListGroup>              
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Header>{right['name']}</Card.Header>
                <ListGroup variant="flush">
                  {rightPicks}
                  {rightPlayers}
                </ListGroup>              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <h3>
              <Link className="link-underline" to={`/trade/${tradeID}`}>Status: {status}</Link>
            </h3>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Trade;