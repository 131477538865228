import React from 'react';
import axios from 'axios';
import Trade from './Trade';

import Stack from 'react-bootstrap/Stack';

import cfg from './appConfig';

class Home extends React.Component {
  state = {
    trades: []
  }
  async componentDidMount() {
    const tradeRequest = await axios(`${cfg.backend}/api/trades`);
    const tradeData = await tradeRequest.data;

    this.setState({ trades: tradeData.trades });
  }

  render() {
    const renderTrades = this.state.trades.reverse().map((trade, index) => {
      return (
        <Trade
          key={index}
          transactionKey={trade['transaction_key']}
          traders={trade['traders']}
          status={trade['status']}
          judges={trade['committee']}/>
      );
    });

    return (
      <Stack gap={5}>
        <h1 className="text-center">Trades</h1>
        {renderTrades}
      </Stack>
    );
  }
}

export default Home;