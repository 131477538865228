let basename = '/trades';
let hostname = `https://${window.location.hostname}`;

if (window.location.hostname === 'localhost') {
  hostname = `http://localhost:8080`;

  basename = '/';
}

export default {
  backend: hostname,
  basename: basename,
  leagueID: '449.l.223276'
};